<template>
	<dialog
		:id="id"
		ref="dialogEl"
		class="lightbox"
		:class="{
			'lightbox--open': state === 'open',
			'lightbox--opening': state === 'opening',
			'lightbox--closing': state === 'closing',
			'lightbox--close': state === 'close',
		}"
		@cancel.prevent="close()"
		@click="onBackdropClick($event)">
		<transition
			@after-enter="onAfterEnter"
			@after-leave="onAfterLeave"
			@before-enter="onBeforeEnter"
			@before-leave="onBeforeLeave"
			@enter="onEnter"
			@leave="onLeave">
			<div v-if="isActive" class="lightbox__content">
				<div class="lightbox__header">
					<div class="align-items-center flex justify-content-between">
						<div class="flex-auto">
							<v-lightbox-share v-if="id" />
						</div>

						<div v-if="media.length > 1" class="flex-auto">
							<v-slide-counter class="lightbox__counter" :slider="sliderEl" />
						</div>

						<div class="flex-auto">
							<v-button
								class="lightbox__close-btn ml-auto"
								color="white"
								fill="outline"
								icon="tepari:cross"
								shape="round"
								type="button"
								@click="close" />
						</div>
					</div>
				</div>

				<div class="lightbox__gallery">
					<v-slider-control
						v-if="media.length > 1"
						class="lightbox__prev-btn"
						color="white"
						:slider="sliderEl"
						type="prev" />

					<v-slider
						ref="sliderEl"
						class="lightbox__media"
						:options="{
							loop: media.length > 1,
							slidesPerView: 1,
							spaceBetween: 80,
						}"
						@slider:change="onSlideChange">
						<v-slide v-for="(item, i) in media" :key="i">
							<v-lightbox-slide :media="item" />
						</v-slide>
					</v-slider>

					<v-slider-control
						v-if="media.length > 1"
						class="lightbox__next-btn"
						color="white"
						:slider="sliderEl"
						type="next" />
				</div>
			</div>
		</transition>
	</dialog>
</template>

<script lang="ts" setup>
	import type Slider from '@/components/common/slider/slider.vue'
	import { isFinite } from 'lodash-es'
	import type Swiper from 'swiper'
	import { isNullOrUndefined } from '~/utilities/is-null-or-undefined'

	const props = defineProps<{
		id?: string
		media: LightboxMedia[]
	}>()

	const emit = defineEmits<{
		close: []
		closing: []
		opening: []
		open: []
	}>()

	const { $gsap } = useNuxtApp()

	const { setUrlParams, getUrlParam } = useRouterUtils()

	const { id, media } = toRefs(props)

	const dialogEl = ref<HTMLDialogElement>(null!)

	const sliderEl = ref<InstanceType<typeof Slider>>(null!)

	const isActive = ref(false)

	const state = ref<'opening' | 'open' | 'closing' | 'close'>('close')

	onMounted(() => {
		const urlId = getUrlParam('lightbox')?.toString()
		const urlIndex = getUrlParam('lightbox-index')?.toString()

		if (!isNullOrUndefined(urlId) && urlId === id.value) {
			open(urlIndex)
		}
	})

	function open(index?: any) {
		isActive.value = true

		index = isFinite(Number(index)) ? Number(index) : 0

		if (id.value) {
			setUrlParams({
				'lightbox': id.value,
				'lightbox-index': index,
			})
		}

		watchOnce(sliderEl, () => {
			sliderEl.value.slideTo(index, 0, true)
		})
	}

	function close() {
		isActive.value = false

		setUrlParams({
			'lightbox': null,
			'lightbox-index': null,
		})
	}

	function toggle(index?: number) {
		if (isActive.value) close()
		else open(index)
	}

	function onBackdropClick({ target }: MouseEvent) {
		if (target instanceof HTMLDialogElement) {
			close()
		}
	}

	async function onEnter(el: Element, done: () => void) {
		const tl = $gsap.timeline({
			onComplete: done,
			paused: true,
		})

		const media = el.querySelectorAll('.lightbox__media')

		tl.fromTo(
			el,
			{
				opacity: 0,
			},
			{
				clearProps: 'all',
				duration: 0.3,
				ease: 'ease',
				opacity: 1,
			},
			0,
		)

		if (media.length) {
			tl.fromTo(
				media,
				{
					scale: 0.9,
				},
				{
					clearProps: 'all',
					duration: 0.3,
					ease: 'power2.out',
					scale: 1,
				},
				0,
			)
		}

		await tl.play()
	}

	async function onLeave(el: Element, done: () => void) {
		const tl = $gsap.timeline({
			onComplete: done,
			paused: true,
		})

		tl.to(el, {
			clearProps: 'all',
			duration: 0.3,
			ease: 'power2.out',
			opacity: 0,
		})

		await tl.play()
	}

	function onAfterEnter() {
		state.value = 'open'
		emit('open')
	}

	function onAfterLeave() {
		dialogEl.value?.close()
		state.value = 'close'
		emit('close')
	}

	function onBeforeEnter() {
		dialogEl.value?.showModal()
		state.value = 'opening'
		emit('opening')
	}

	function onBeforeLeave() {
		state.value = 'closing'
		emit('closing')
	}

	function onSlideChange(swiper: Swiper) {
		if (!id.value) return

		setUrlParams({
			'lightbox': id.value,
			'lightbox-index': swiper.realIndex,
		})
	}

	defineExpose({
		close,
		open,
		toggle,
	})

	provide<ProvidedProps>('lightbox', {
		close,
		open,
		toggle,
	})
</script>

<script lang="ts">
	export interface LightboxAsset {
		alt?: string
		title?: string
		caption?: string
		width: number | `${number}`
		height: number | `${number}`
	}

	export interface LightboxEmbed extends LightboxAsset {
		type: 'embed'
		url: string
	}

	export interface LightboxImage extends LightboxAsset {
		type: 'image'
		url: string
	}

	export interface LightboxVideo extends LightboxAsset {
		type: 'video'
		sources: Source[]
	}

	export type LightboxMedia = LightboxEmbed | LightboxImage | LightboxVideo

	export interface ProvidedProps {
		close: () => void
		open: (index?: number) => void
		toggle: () => void
	}
</script>

<style lang="scss">
	@layer components {
		.lightbox {
			width: 100%;
			height: 100%;
			color: $white;

			&::backdrop {
				opacity: 0;
				backdrop-filter: blur(5px);
				transition: opacity 300ms ease;
				background-color: rgba($gray-900, 0.9);
			}

			&--open,
			&--opening {
				&::backdrop {
					opacity: 1;
				}
			}

			&__content {
				display: grid;
				position: relative;
				grid-template-rows: auto minmax(0, 1fr);
				grid-template-columns: 100%;
				width: 100%;
				height: 100%;
				overflow: hidden;
				pointer-events: none;
			}

			&__header {
				background-color: rgba(0, 0, 0, 0.5);
				padding: 10px 20px;
				pointer-events: all;
			}

			&__gallery {
				display: flex;
				align-items: center;
				gap: 20px;
				padding: 20px;
				width: 100%;
				height: 100%;
			}

			&__media {
				flex: 1 1 auto;
				width: 100%;
				height: 100%;

				iframe,
				img,
				video {
					pointer-events: all;
				}
			}

			&__prev-btn {
				flex: 0 0 auto;
				pointer-events: all;
			}

			&__next-btn {
				flex: 0 0 auto;
				pointer-events: all;
			}
		}
	}
</style>

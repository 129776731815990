<template>
	<component
		:is="component"
		v-ripple
		v-bind="componentAttrs"
		class="menu__item"
		:class="{
			'menu__item--active': item.active?.(),
		}"
		@click="onClick">
		<slot name="item" v-bind="{ item }">
			<icon v-if="item.icon" :name="item.icon" size="18px" />
			{{ item.label }}
		</slot>
	</component>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		item: MenuItem
	}>()

	const emit = defineEmits<{
		'menu:click': [MenuItem]
	}>()

	const type = computed(() => {
		if (props.item.command) {
			return 'button'
		}

		if (props.item.link) {
			return props.item.link.startsWith('http') ? 'a' : 'NuxtLink'
		}

		return 'p'
	})

	const component = computed(() => {
		return type.value === 'NuxtLink' ? resolveComponent('NuxtLink') : type.value
	})

	const componentAttrs = computed(() => {
		switch (type.value) {
			case 'a':
				return {
					href: props.item.link,
					target: '_blank',
				}

			case 'button':
				return {
					type: 'button',
				}

			case 'NuxtLink':
				return {
					to: props.item.link,
					activeClass: 'menu__item--active',
					exactActiveClass: 'menu__item--exact-active',
				}
		}
	})

	function onClick() {
		emit('menu:click', props.item)
		props.item.command?.()
	}
</script>

<script lang="ts">
	export interface MenuItem {
		active?: () => boolean
		command?: () => void
		icon?: string
		label: string
		link?: string
	}
</script>

<style lang="scss">
	@layer components {
		.menu__item {
			@include var-pad('menu-item', 10px, 5px);

			display: flex;
			position: relative;
			align-items: center;
			gap: 10px;
			transition: background-color 300ms ease;
			border-radius: 5px;
			width: 100%;
			min-height: 36px;
			overflow: hidden;
			color: $gray-700;
			font-size: 1.4rem;
			line-height: 2rem;

			&:hover {
				background-color: $gray-100;
			}

			&--active {
				background-color: rgba($brand, 0.1) !important;
			}

			svg {
				flex: 0 0 auto;
			}
		}
	}
</style>

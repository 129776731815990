<template>
	<v-dropdown ref="dropdownEl" class="dropdown-menu">
		<v-menu :items="items" @menu:click="dropdownEl?.hide">
			<template v-if="slots.item" #item="slotProps">
				<slot name="item" v-bind="slotProps" />
			</template>
		</v-menu>
	</v-dropdown>
</template>

<script lang="ts" setup>
	import type { MenuItem } from '../menu/menu-item.vue'
	import Dropdown from '@/components/common/dropdown/dropdown.vue'

	const props = defineProps<{
		items: MenuItem[]
	}>()

	const slots = useSlots()

	const dropdownEl = ref<InstanceType<typeof Dropdown>>()

	defineExpose({
		hide: () => dropdownEl.value?.hide(),
		show: (e: Event | Element) => dropdownEl.value?.show(e),
		toggle: (e: Event | Element) => dropdownEl.value?.toggle(e),
	})
</script>

<style lang="scss">
	@layer components {
		.dropdown-menu {
			.menu {
				border: none;
				border-radius: 0;
			}
		}
	}
</style>

<template>
	<v-button
		class="lightbox__share-btn"
		color="white"
		fill="outline"
		icon="tepari:share"
		shape="round"
		type="button"
		@click="shareMenuEl?.toggle" />

	<v-dropdown-menu
		ref="shareMenuEl"
		class="lightbox__share-menu"
		:items="shareMenuItems"
		:offset="{
			mainAxis: 10,
		}"
		placement="right-start">
		<template #item="{ item }">
			{{ item.label }}
			<icon v-if="item.icon" class="ml-auto" :name="item.icon" size="18px" />
		</template>
	</v-dropdown-menu>
</template>

<script lang="ts" setup>
	import type { MenuItem } from '../menu/menu-item.vue'
	import type DropdownMenu from '@/components/common/dropdown/dropdown-menu.vue'
	import { getShareUrl, type Platform } from '@/utilities/get-share-url'

	const clipboard = useClipboard()

	const shareMenuEl = ref<InstanceType<typeof DropdownMenu>>()

	const shareMenuItems: MenuItem[] = [
		{
			command: () => {
				if (!import.meta.browser) return
				clipboard.copy(window.location.href)
			},
			icon: 'ion:link',
			label: 'Copy link',
		},
		{
			command: () => {
				if (!import.meta.browser) return
				window.location.href = 'mailto:?body=' + window.location.href
			},
			icon: 'ion:mail-open-outline',
			label: 'Share via email',
		},
		{
			command: () => shareTo('facebook'),
			icon: 'IconFacebook',
			label: 'Share on Facebook',
		},
		{
			command: () => shareTo('linkedin'),
			icon: 'IconLinkedin',
			label: 'Share on LinkedIn',
		},
		{
			command: () => shareTo('twitter'),
			icon: 'IconTwitter',
			label: 'Share on Twitter/X',
		},
	]

	function shareTo(platform: Platform) {
		if (!process.browser) return
		const shareUrl = getShareUrl(platform, window.location.href)
		window.open(shareUrl, '_blank')
	}
</script>

<style lang="scss">
	@layer components {
		.lightbox__share-menu {
			max-width: 250px;
		}
	}
</style>

<template>
	<div class="menu">
		<ul class="menu__body" role="menu">
			<template v-for="(item, itemIndex) in items" :key="itemIndex">
				<template v-if="isItemGroup(item)">
					<li role="presentation">
						<v-menu-title :title="item.label">
							<template v-if="slots.title" #title="slotProps">
								<slot name="title" v-bind="slotProps" />
							</template>
						</v-menu-title>
					</li>
					<li
						v-for="(childItem, childItemIndex) in item.items"
						:key="childItemIndex"
						role="menuitem">
						<v-menu-item :item="childItem">
							<template v-if="slots.item" #item="slotProps">
								<slot name="item" v-bind="slotProps" />
							</template>
						</v-menu-item>
					</li>
				</template>
				<template v-else-if="isItem(item)">
					<li role="menuitem">
						<v-menu-item :item="item">
							<template v-if="slots.item" #item="slotProps">
								<slot name="item" v-bind="slotProps" />
							</template>
						</v-menu-item>
					</li>
				</template>
			</template>
		</ul>
	</div>
</template>

<script lang="ts" setup>
	import { isArray } from 'lodash-es'
	import type { MenuItem } from './menu-item.vue'

	const props = defineProps<{
		items: Array<MenuItem | MenuItemGroup>
	}>()

	const slots = useSlots()

	function isItemGroup(value: any): value is MenuItemGroup {
		return value && value.items && isArray(value.items)
	}

	function isItem(value: any): value is MenuItem {
		return value && value.items === undefined
	}
</script>

<script lang="ts">
	export interface MenuItemGroup {
		items: MenuItem[]
		label: string
	}
</script>

<style lang="scss">
	@layer components {
		.menu {
			@include var-pad('menu', 10px, 10px);

			border: 1px solid $gray-300;
			border-radius: 5px;
			background-color: $white;

			&__body {
				@include reset-list();

				> li + li {
					margin-top: 5px;
				}
			}
		}
	}
</style>

const PLATFORMS = {
	facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
	linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=',
	twitter: 'https://twitter.com/intent/tweet?url=',
	whatsapp: 'https://api.whatsapp.com/send?text=',
}

export type Platform = keyof typeof PLATFORMS

export function getShareUrl(platform: Platform, url: string) {
	return `${PLATFORMS[platform]}${encodeURIComponent(url)}`
}

<template>
	<div class="slide-counter">
		<span class="slide-counter__current">{{ current }}</span>
		<span class="slide-counter__divider">/</span>
		<span class="slide-counter__total">{{ total }}</span>
	</div>
</template>

<script lang="ts" setup>
	import Slider from './slider.vue'

	const props = defineProps<{
		slider?: InstanceType<typeof Slider>
	}>()

	const current = computed(() => (props.slider?.activeIndex ?? 0) + 1)

	const total = computed(() => props.slider?.slides?.length ?? 0)
</script>

<style lang="scss">
	@layer components {
		.slide-counter {
			user-select: none;
		}
	}
</style>

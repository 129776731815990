<template>
	<figure class="flex flex-col gap-20 h-full">
		<div ref="el" class="flex-fill relative">
			<div
				class="absolute inset-1/2 -translate-1/2-1/2"
				:style="{
					width: width,
					height: height,
				}">
				<v-lightbox-embed
					v-if="isEmbed(media)"
					class="h-full w-full"
					:embed="media" />

				<v-lightbox-image
					v-if="isImage(media)"
					class="h-full w-full"
					:image="media" />

				<v-lightbox-video
					v-if="isVideo(media)"
					class="h-full w-full"
					:video="media" />
			</div>
		</div>

		<figcaption v-if="media.caption" class="flex-auto text-center">
			{{ media.caption }}
		</figcaption>
	</figure>
</template>

<script lang="ts" setup>
	import type {
		LightboxEmbed,
		LightboxImage,
		LightboxMedia,
		LightboxVideo,
	} from './lightbox.vue'

	const props = defineProps<{
		media: LightboxMedia
	}>()

	const el = ref<HTMLElement>(null!)
	const width = ref('auto')
	const height = ref('auto')

	onMounted(() => {
		calculateDimensions()
	})

	useResizeObserver(el, calculateDimensions)

	function calculateDimensions() {
		width.value = 'auto'
		height.value = 'auto'

		const { width: maxWidth, height: maxHeight } =
			el.value.getBoundingClientRect()

		const originalWidth = Number(props.media.width)
		const originalHeight = Number(props.media.height)

		if (originalWidth / maxWidth > originalHeight / maxHeight) {
			width.value = `${maxWidth}px`
			height.value = `${(originalHeight / originalWidth) * maxWidth}px`
		} else {
			width.value = `${(originalWidth / originalHeight) * maxHeight}px`
			height.value = `${maxHeight}px`
		}
	}

	function isEmbed(media: LightboxMedia): media is LightboxEmbed {
		return media.type === 'embed'
	}

	function isImage(media: LightboxMedia): media is LightboxImage {
		return media.type === 'image'
	}

	function isVideo(media: LightboxMedia): media is LightboxVideo {
		return media.type === 'video'
	}
</script>

<style lang="scss" scoped></style>

import type {
	LightboxEmbed,
	LightboxImage,
	LightboxMedia,
	LightboxVideo,
} from '@/components/common/lightbox/lightbox.vue'
import type { EmbedAsset } from '@/graphql/embed'
import type { ImageAsset } from '@/graphql/image'
import type { VideoAsset } from '@/graphql/video'
import type { MaybeArray } from '@/types/maybe-array'
import { isArray } from 'lodash-es'
import { isCraftEmbed, isCraftImage, isCraftVideo } from './craft'

export function getLightboxMedia(items: any) {
	const lightboxMedia: LightboxMedia[] = []

	if (!items) return lightboxMedia

	items = isArray(items) ? items : [items]

	items.forEach((item: any) => {
		let media: LightboxMedia | null = null

		if (isCraftEmbed(item)) media = getLightboxEmbed(item)
		if (isCraftImage(item)) media = getLightboxImage(item)
		if (isCraftVideo(item)) media = getLightboxVideo(item)

		if (media) lightboxMedia.push(media)
	})

	return lightboxMedia
}

function getLightboxEmbed(item: MaybeArray<EmbedAsset>) {
	const embed = isArray(item) ? item[0] : item

	return embed
		? <LightboxEmbed>{
				type: 'embed',
				alt: embed.alt,
				title: embed.title,
				caption: embed.caption,
				width: embed.oEmbed?.width,
				height: embed.oEmbed?.height,
				url: embed.oEmbed?.src,
			}
		: null
}

function getLightboxImage(item: MaybeArray<ImageAsset>) {
	const img = isArray(item) ? item[0] : item

	return img
		? <LightboxImage>{
				type: 'image',
				alt: img.alt ?? img.title,
				title: img.title,
				caption: img.caption,
				width: img.width,
				height: img.height,
				url: img.url,
			}
		: null
}

function getLightboxVideo(item: MaybeArray<VideoAsset>) {
	const video = isArray(item) ? item[0] : item

	return video
		? <LightboxVideo>{
				type: 'video',
				alt: video.alt,
				title: video.title,
				caption: video.alt,
				width: video.width,
				height: video.height,
				sources: [
					{
						src: video.url,
						type: video.type,
					},
				],
			}
		: null
}
